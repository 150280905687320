import { useRef } from 'react';
import { getClasses } from '@pasqal/core/helpers/styles';
import useClickOutside from '@pasqal/core/hooks/useClickOutside';
import useLockBodyScroll from '@pasqal/core/hooks/useLockBodyScroll';
import Button from '@pasqal/core/ui/components/button/Button';
import Stack from '@pasqal/core/ui/components/layout/Stack';
import { Text } from '@pasqal/core/ui/components/typography/Text';

import type { IComponentWithChildren } from '@pasqal/core/ui/components/types';

import './drawer.css';

export interface IProps extends IComponentWithChildren {
  isOpen: boolean;
  onClose: () => void;
  role?: 'dialog' | 'menu';
  ariaLabel?: string;
  className?: string;
  testId?: string;
  title?: string;
}

export const Drawer = ({
  isOpen,
  role = 'dialog',
  ariaLabel,
  children,
  className,
  testId,
  title,
  onClose
}: IProps) => {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const css = getClasses(['Drawer', isOpen && 'is-open', className]);

  useLockBodyScroll(isOpen);

  useClickOutside({
    el: panelRef,
    handler: (e) => {
      e.stopPropagation();
      onClose();
    },
    isOpen,
    isActive: isOpen
  });

  return (
    <div className={css} data-testid={testId}>
      <div aria-hidden="true" className="Drawer-overlay"></div>
      <div
        ref={panelRef}
        role={role}
        aria-modal="true"
        aria-label={ariaLabel}
        tabIndex={-1}
        className="Drawer-panel"
      >
        <Stack className="Drawer-inner" spacing="300">
          <Stack direction="horizontal" alignY="center" isDispatchingX>
            <Text variant="largeHighlighted">{title}</Text>
            <Button
              className="Drawer-closeButton"
              variant="text"
              size="sm"
              iconRight="close"
              onClick={onClose}
            >
              Close
            </Button>
          </Stack>
          <div className="Drawer-body">{children}</div>
        </Stack>
      </div>
    </div>
  );
};
