import debounce from 'lodash.debounce';
import { cloneElement, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { getClasses } from '@pasqal/core/helpers/styles';
import useClickOutside from '@pasqal/core/hooks/useClickOutside';
import useIsLargeScreen from '@pasqal/core/hooks/useIsLargeScreen';
import useIsomorphicLayoutEffect from '@pasqal/core/hooks/useIsomorphicLayoutEffect';
import useToggle from '@pasqal/core/hooks/useToggle';
import Drawer from '@pasqal/core/ui/components/Drawer';

import type { MouseEvent, ReactElement, ReactNode } from 'react';

import '@pasqal/core/ui/components/dropdown/Dropdown/dropdown.css';

let activeDropdownStack: HTMLElement[] = [];

const getMenuPosition = (
  menu: DOMRect,
  target: DOMRect,
  placement: 'left' | 'right' | 'top' | 'bottom',
  alignToEnd: boolean,
  useTargetWidth: boolean
) => {
  const pos = { x: target.left, y: target.bottom };

  const SPACING = 8;
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const menuWidth = useTargetWidth ? target.width : menu.width;

  if (
    placement === 'bottom' &&
    target.bottom + menu.height + SPACING > viewportHeight
  ) {
    placement = 'top';
  } else if (placement === 'top' && target.top - menu.height - SPACING < 0) {
    placement = 'bottom';
  }

  if (placement === 'left' && target.left - menu.width - SPACING < 0) {
    placement = 'right';
  } else if (
    placement === 'right' &&
    target.right + menu.width + SPACING > viewportWidth
  ) {
    placement = 'left';
  }

  switch (placement) {
    case 'top':
      pos.y = target.top - menu.height - SPACING;
      pos.x = target.left;
      break;
    case 'bottom':
      pos.y = target.bottom + SPACING;
      pos.x = target.left;
      break;
    case 'left':
      pos.x = target.left - menu.width - SPACING;
      pos.y = target.top;
      break;
    case 'right':
      pos.x = target.right + SPACING;
      pos.y = target.top;
      break;
  }

  if (alignToEnd) {
    if (placement === 'top' || placement === 'bottom') {
      pos.x = target.right - menu.width;
    } else if (placement === 'left' || placement === 'right') {
      pos.y = target.bottom - menu.height;
    }
  } else {
    if (menuWidth <= viewportWidth - target.left) {
      pos.x = target.left;
    } else {
      pos.x = viewportWidth - menu.width;
    }
  }

  pos.x = Math.min(viewportWidth - menu.width - SPACING, Math.max(0, pos.x));
  pos.y = Math.min(viewportHeight - menu.height - SPACING, Math.max(0, pos.y));

  return pos;
};

interface IProps {
  id: string;
  className?: string;
  button: ReactElement;
  dropdownTitle?: string;
  dropdownContent: ReactNode;
  isOpen?: boolean;
  ariaLabel?: string;
  onToggle?: () => void;
  onClose?: () => void;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  alignToEnd?: boolean;
  isDisabled?: boolean;
  keepOpenOnMenuClick?: boolean;
  type?: 'menu' | 'combobox';
  testId?: string;
  matchButtonWidth?: boolean;
}

const styles = {
  base: 'Dropdown',
  isOpen: 'is-open',
  isDisabled: 'is-disabled'
};

export const Dropdown = ({
  id,
  className,
  button,
  dropdownTitle,
  dropdownContent,
  isOpen: controlledIsOpen,
  ariaLabel,
  onToggle,
  onClose,
  placement = 'left',
  alignToEnd = false,
  isDisabled = false,
  type = 'menu',
  keepOpenOnMenuClick = false,
  testId,
  matchButtonWidth = false
}: IProps) => {
  const [position, setPosition] = useState({
    left: '0px',
    top: '0px'
  });
  const [menuWidth, setMenuWidth] = useState('auto');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const isLargeScreen = useIsLargeScreen();
  const {
    isOpen: internalIsOpen,
    handleToggle,
    handleClose: internalHandleClose
  } = useToggle(false);
  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : internalIsOpen;

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      internalHandleClose();
    }
    activeDropdownStack = activeDropdownStack.filter(
      (el) => el !== dropdownRef.current
    );
  };

  const mainCss = getClasses([
    styles.base,
    isOpen && styles.isOpen,
    isDisabled && styles.isDisabled,
    className
  ]);
  const dropdownMenuId = `${id}-menu`;

  const handleToggleButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onToggle) {
      onToggle();
    } else {
      handleToggle();
    }

    if (isOpen) {
      activeDropdownStack = activeDropdownStack.filter(
        (el) => el !== dropdownRef.current
      );
    } else if (
      dropdownRef.current &&
      !activeDropdownStack.includes(dropdownRef.current)
    ) {
      activeDropdownStack.push(dropdownRef.current);
    }
  };

  const handleMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    // close the menu after an item has been clicked
    if (!keepOpenOnMenuClick) {
      handleClose();
    }
  };

  useClickOutside({
    el: dropdownRef,
    handler: (e) => {
      if (
        menuRef.current &&
        activeDropdownStack[activeDropdownStack.length - 1] ===
          dropdownRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        handleClose();
      }
    },
    isOpen,
    isActive: true
  });

  useIsomorphicLayoutEffect(() => {
    const setMenuPosition = () => {
      if (dropdownRef.current !== null && menuRef.current !== null) {
        const target = dropdownRef.current.getBoundingClientRect();
        const menu = menuRef.current.getBoundingClientRect();

        if (matchButtonWidth) {
          setMenuWidth(`${target.width}px`);
        }

        const pos = getMenuPosition(
          menu,
          target,
          placement,
          alignToEnd,
          matchButtonWidth
        );

        setPosition({
          left: `${pos.x}px`,
          top: `${pos.y}px`
        });
      }
    };

    if (isOpen) {
      setMenuPosition();
    }

    const repositionListener = debounce(setMenuPosition, 300);
    const scrollListener = () => {
      setMenuPosition();
    };

    window.addEventListener('resize', repositionListener);
    window.addEventListener('scroll', scrollListener, true);

    return () => {
      window.removeEventListener('resize', repositionListener);
      window.removeEventListener('scroll', scrollListener, true);
    };
  }, [isOpen, placement, matchButtonWidth, alignToEnd, menuWidth]);

  return (
    <div className={mainCss} ref={dropdownRef} data-testid={testId}>
      <div
        className="Dropdown-toggleButton"
        onClick={handleToggleButtonClick}
        data-testid={testId && `${testId}-toggle-button`}
      >
        {cloneElement(button, {
          'aria-expanded': isOpen,
          'aria-haspopup': type === 'menu' ? 'menu' : 'listbox',
          'aria-controls': dropdownMenuId,
          'aria-label': ariaLabel,
          'aria-disabled': isDisabled
        })}
      </div>
      {isOpen &&
        isLargeScreen &&
        createPortal(
          <div
            id={dropdownMenuId}
            className="Dropdown-menu"
            ref={menuRef}
            style={{
              left: position.left,
              top: position.top,
              width: menuWidth
            }}
            data-testid={testId && `${testId}-dropdown-menu`}
            onClick={handleMenuClick}
          >
            {dropdownContent}
          </div>,
          document.body
        )}
      {isOpen && !isLargeScreen && (
        <Drawer
          isOpen={isOpen}
          onClose={handleClose}
          role={type === 'menu' ? 'menu' : 'dialog'}
          ariaLabel={ariaLabel}
          testId={testId && `${testId}-drawer`}
          title={dropdownTitle}
        >
          {dropdownContent}
        </Drawer>
      )}
    </div>
  );
};
