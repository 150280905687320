import { useEffect, useState } from 'react';

const useIsLargeScreen = (widthBreakpoint = 860) => {
  const [isLargeScreen, setIsLargeScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth >= widthBreakpoint : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= widthBreakpoint);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [widthBreakpoint]);

  return isLargeScreen;
};
export default useIsLargeScreen;
